import api from '../configs/api';

export default class ExpenditureService {

	async findAll() {
		return api.get(`/admin/expenditure`);
	}

	async findById(id) {
		return api.get(`/admin/expenditure/` + id);
	}

	async findByInventory(id) {
		return api.get(`/admin/expenditure/inventory/` + id);
	}

	async save(expenditure) {
		return api.post(`/admin/expenditure`, expenditure);
	}

	async delete(id) {
		return api.delete(`/admin/expenditure/` + id);
	}
}
