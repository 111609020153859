<template>
  <div class="grid mt-5">
    <div class="col-12">
      <div class="card">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label for="categoria">Produto *</label>
            <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
                        <i class="pi pi-list"></i>
                    </span>
              <Dropdown id="categoria" v-model="expenditure.product" :options="products"
                        data-key="id"
                        optionLabel="name" placeholder="Selecione um Produto"
                        autofocus :class="{'p-invalid': v$.expenditure.product.$error}"
              />
            </div>
            <small class="p-invalid" v-for="error of v$.expenditure.product.$errors"
                   :key="error.$uid">{{error.$message}}</small>
          </div>
          <div class="field col-12 md:col-12">
            <label for="name">Valor Unitário *</label>
            <div class="p-inputgroup">
              <InputNumber v-model="expenditure.value" mode="currency"
                           @focusout="priceTotal"
                           aria-required="true"
                           autofocus :class="{'p-invalid': v$.expenditure.value.$error}"
                           locale="pt-BR" currency="BRL"
                           :minFractionDigits="2"
                           aria-placeholder="R$"
                           placeholder="R$ 0,00"
              />
            </div>
            <small class="p-invalid" v-for="error of v$.expenditure.value.$errors"
                   :key="error.$uid">{{error.$message}}</small>
          </div>
          <div class="field col-6 md:col-6">
            <label for="name">Quantidade (Caixa, embalagem, etc...) *</label>
            <div class="p-inputgroup">
              <InputNumber id="name"
                           @focusout="priceTotal"
                           mode="decimal"
                           v-model="expenditure.amount" required="true"
                           autofocus :class="{'p-invalid': v$.expenditure.amount.$error}" />
            </div>
            <small class="p-invalid" v-for="error of v$.expenditure.amount.$errors"
                   :key="error.$uid">{{error.$message}}</small>
          </div>
          <div class="field col-6 md:col-6">
            <label for="name">Unidade *</label>
            <div class="p-inputgroup">
              <InputNumber id="name"
                           mode="decimal"
                           v-model="expenditure.unit" required="true"
                           autofocus :class="{'p-invalid': v$.expenditure.unit.$error}" />
            </div>
            <small class="p-invalid" v-for="error of v$.expenditure.unit.$errors"
                   :key="error.$uid">{{error.$message}}</small>
          </div>
          <div class="field col-12 md:col-12">
            <label for="name">Total</label>
            <div class="p-inputgroup">
              <InputNumber id="name"
                           disabled="true"
                           mode="currency"
                           locale="pt-BR" currency="BRL"
                           :minFractionDigits="2"
                           aria-placeholder="R$"
                           placeholder="R$ 0,00"
                           v-model.trim="expenditure.total"
                           autofocus />
            </div>
          </div>

        </div>
        <div class="text-right">
          <Button label="Adicionar" icon="pi pi-plus" class="p-button-success" @click.prevent="addExpenditure" />
        </div>
      </div>
    </div>
  </div>
  <Panel header="Lista de Produtos" class="mt-3">
    <DataTable :value="expenditures" dataKey="id" responsiveLayout="scroll" >
      <Column field="product.name" header="Produto"></Column>
      <Column field="amount" header="Quantidade"></Column>
      <Column field="unit" header="Unidade"></Column>
      <Column field="value" header="Valor Unitário">
        <template #body="slotProps">
          <span class="p-column-title">Valor Unitário</span>
          {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(slotProps.data.value)}}
        </template>
      </Column>
      <Column field="total" header="Total" align="right" header-style="text-align: right">
        <template #body="slotProps">
          <div style="text-align:right; justify-content: space-between">
            {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(slotProps.data.total)}}
          </div>
        </template>
      </Column>
      <template #footer>
        <div style="text-align:right; justify-content: space-between">
          <span class="p-column-title text-center text-pink-700">Total: </span>
          <span class="text-pink-700">{{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(total)}}</span>
        </div>
      </template>
      <Column>
        <template #body="slotProps">
          <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click.prevent="confirmDelete(slotProps.data)" />
        </template>
      </Column>
    </DataTable>
  </Panel>

  <Toolbar class="mt-3">
    <template v-slot:end>
      <div class="my-2">
        <Button label="Anterior" icon="pi pi-angle-left" icon-pos="left" @click="prevPage()" class="mr-2"/>
        <Button label="Próximo" icon="pi pi-angle-right" icon-pos="right" @click="nextPage()" />
      </div>
    </template>
  </Toolbar>

  <Dialog v-model:visible="deleteProdutoDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="produto">Você pode ficar com falta no seu estoque. Tem certeza de que deseja excluir <b>{{ produto.name }}</b>?</span>
    </div>
    <template #footer>
      <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProdutoDialog = false"/>
      <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="removeExpenditure(produto.id)" />
    </template>
  </Dialog>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import ProductService from "@/service/ProductService";
import ExpenditureService from "@/service/ExpenditureService";
import moment from "moment/moment";

export default {
  name: "tipo-compra",
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      expenditure: {
        inventory: {}
      },
      expenditures: this.formData.expenditures ? this.formData.expenditures : [],
      products: [],
      productService: null,
      total: 0,
      inventoryService: null,
      expenditureService: null,
      inventoryId: null,
      loader: null,
      deleteProdutoDialog: false,
      produto: null
    }
  },
  props: {
    formData: Object
  },
  validations() {
    return {
      expenditure: {
        amount: {
          required: helpers.withMessage('Campo quantidade é obrigatório.', required)
        },
        value: {
          required: helpers.withMessage('Campo valor unitário é obrigatório.', required)
        },
        unit: {
          required: helpers.withMessage('Campo valor unidade é obrigatório.', required)
        },
        product: {
          required: helpers.withMessage('Campo produtos é obrigatório.', required)
        }
      }
    }
  },
  created() {
    this.moment = moment;
    moment.locale('pt-BR');
    this.productService = new ProductService();
    this.expenditureService = new ExpenditureService();
  },
  async mounted() {
    this.v$.$reset();
    this.loader = this.$loading.show();
    this.inventoryId = this.$route.query.id;

    await Promise.all([
      this.findAllProducts(),
      this.findAllExpenditure()
    ]);
    this.loader.hide();
  },
  methods: {

    async nextPage() {
      if(this.expenditures.length > 0) {
        this.$emit('next-page', {
          formData: {
            expenditures: this.expenditures,
            total: this.total
          },
          pageIndex: 1,
          despesaId: this.despesaId
        });
      }
      this.$toast.add({severity:'warn', summary: 'Alerta', detail: 'Você deve informar ao menos um produto.', life: 3000});
    },

    prevPage() {
      this.$emit('prev-page', {pageIndex: 1, despesaId: this.inventoryId});
    },

    confirmDelete(produto) {
      this.produto = produto;
      this.deleteProdutoDialog = true;
    },

    async findById() {
      if(this.inventoryId) {
        const response = await this.expenditureService.findByInventory(this.inventoryId);
        if(response.status === 200) {
          this.expenditures = response.data.content;
        }
      }
    },

    async findAllExpenditure() {
      const response = await this.expenditureService.findByInventory(this.inventoryId);
      if(response.status === 200) {
        this.total = 0;
        this.expenditures = response.data.content;
        this.expenditures.forEach((resp) => {
          this.total += resp.total;
        })
      }
    },
    async findAllProducts() {
      const response = await this.productService.findAll();
      if (response.status === 200) {
        this.products = response.data.content;
      }
    },
    priceTotal() {
      if (this.expenditure.value && this.expenditure.amount) {
        this.expenditure.total = this.expenditure.value * this.expenditure.amount;
      }
    },

    async addExpenditure() {
      const isFormValid = await this.v$.$validate();
      if(isFormValid) {
        const inventory = {
          id: this.inventoryId
        };
        this.loader = this.$loading.show();
        this.expenditure.unit = this.expenditure.unit * this.expenditure.amount;
        this.expenditure.inventory = inventory;
        const response = await this.expenditureService.save(this.expenditure);
        if (response.status === 200) {
          await this.findAllExpenditure();
          this.expenditure = {
            inventory: {
              id: null
            }
          };
          this.v$.$reset();
          this.$toast.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Produto incluído com sucesso.',
            life: 3000
          });
        }
        this.loader.hide();
      }
    },
    async removeExpenditure() {
      this.loader = this.$loading.show();
      console.info(`TT: ${this.produto.id}`);
      const response = await this.expenditureService.delete(this.produto.id);
      if(response.status === 200) {
        await this.findAllExpenditure();
        this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Produto removido com sucesso.', life: 3000});
      }
      this.deleteProdutoDialog = false;
      this.loader.hide();
    }
  }
}
</script>

<style scoped>

</style>
